.topHeader {
  background-color: rgba(18, 17, 58, 1);
  height: 3.5rem;
  width: 100%;
  z-index: 999;
  transition: position;
  transition-time: 1s;
}

/* ACTIVE WHEN SCROLLED - FIX HEADER TO TOP */
@keyframes fixHeader {
  0% {
    transform: translateY(-50px)
  }
}

.scrolled {
  margin-bottom: 2.5rem;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-animation-name: fixHeader;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-direction: normal;
  animation-name: fixHeader;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
}


header img {
  position: relative;
  top: 1rem;
  left: 0;
  height: 1.5rem;
}

header a {
  padding: 1rem;
}

header a:hover {
  cursor: pointer;
}
