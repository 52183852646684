html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h1 {
  font-size: 2.5rem;
}

h2 {

}

h3 {

}



p {
  margin: 0 auto;
  padding: 0rem 1rem;
  font-size: 1.15rem;
  max-width: 50ch;
}

a {
  text-decoration: none;
}



@media only screen and (min-width: 600px) {

  h1 {
    font-size: 3.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
   font-size: 1.35rem;
  }



}
