footer {
  padding: 1.5rem;

  background-color: rgba(18, 17, 58, 1);
}

.footerItems {

  text-align: center
}

.returnTopButton {
  color: grey;
  text-align: center;
}

footer a {
  margin: 0rem;
  padding: 1rem;
  display: inline-block;
  font-size: 1rem;
  color: grey;
  text-decoration: none;
}

footer a:hover {
  color: white;
}


.watermark {
  padding-top: 1rem;
  padding-bottom: 0rem;
  font-size: .9rem;
  text-align: center;
  color: grey;
}
