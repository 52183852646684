* {
  margin: 0;
  padding: 0;
}

.bannerBackground {
  background-image: linear-gradient(rgba(18, 17, 58, 1), rgba(18, 17, 58, 1));
  height: 100vh;
  overflow: hidden;
}

/* SNOWFLAKE ANIMATION */
@-webkit-keyframes snowflakes-fall {
  0% {top: -10%} 100% {top:100%}
}
@-webkit-keyframes snowflakes-shake {
  0% {-webkit-transform: translateX(0px);
      transform: translateX(0px)
    } 50% {-webkit-transform: translateX(80px);
           transform: translateX(80px)
      } 100% {-webkit-transform: translateX(0px);
              transform: translateX(0px)
        }
      }
@keyframes snowflakes-fall {
  0% {top: -10%} 100% {top: 100%}
}
@keyframes snowflakes-shake {
  0% {transform: translateX(0px)}
  50% {transform: translateX(25px)}
  100% {transform: translateX(0px)}
  }
.snowflake {
  position: absolute;
  top: -10%;
  z-index: 1;
  color: #FFFFFF;
  font-size: 2rem;
  text-shadow: 0 0 1px #FFFAFA;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall,snowflakes-shake;
  -webkit-animation-duration: 20s,10s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall,snowflakes-shake;
  animation-duration: 20s,10s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running
}
  .snowflake:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s;
}
  .snowflake:nth-of-type(1) {
  left: 10%;
  -webkit-animation-delay: 2s, 1s;
  animation-delay: 2s, 1s;
}
  .snowflake:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 6s, .5s;
  animation-delay: 6s, .5s;
}
  .snowflake:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 14s, 2.5s;
  animation-delay: 14s, 2.5s;
}
  .snowflake:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s;
}
  .snowflake:nth-of-type(5) {
  left: 62.5%;
  -webkit-animation-delay: 6.5s, 4s;
  animation-delay: 6.5s, 4s;
}
  .snowflake:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 8.5s, 2s;
  animation-delay: 8.5s, 2s;
}
  .snowflake:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s, s;
  animation-delay: 2.5s, 1s;
}
  .snowflake:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 5s, 0s;
  animation-delay: 5s, 0s;
}
  .snowflake:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 9s, 1.5s;
  animation-delay: 9s, 1.5s;
}
  .snowflake:nth-of-type(10) {
  left: 2.5%;
  -webkit-animation-delay: 11.5s, 0s;
  animation-delay: 11.5s, 0s;
}
  .snowflake:nth-of-type(11) {
  left: 15%;
  -webkit-animation-delay: 9.5s, 4.5s;
  animation-delay: 9.5s, 4.5s;
}
  .snowflake:nth-of-type(12) {
  left: 25%;
  -webkit-animation-delay: 19s, 3.5s;
  animation-delay: 19s, 3.5s;
}
  .snowflake:nth-of-type(13) {
  left: 33.3%;
  -webkit-animation-delay: 11s, 5s;
  animation-delay: 11s, 5s;
}
  .snowflake:nth-of-type(14) {
  left: 39.5%;
  -webkit-animation-delay: 10.5s, 2s;
  animation-delay: 10.5s, 2s;
}
  .snowflake:nth-of-type(15) {
  left: 58.5%;
  -webkit-animation-delay: 13s, 1.5s;
  animation-delay: 13s, 1.5s;
}
  .snowflake:nth-of-type(16) {
  left: 67%;
  -webkit-animation-delay: 18.5s, 1s;
  animation-delay: 18.5s, 1s;
}
  .snowflake:nth-of-type(17) {
  left: 75%;
  -webkit-animation-delay: 13.5s, 8s;
  animation-delay: 13.5s, 8s;
}
  .snowflake:nth-of-type(18) {
  left:83%;
  -webkit-animation-delay: 16s, 2s;
  animation-delay: 16s, 2s;
}
  .snowflake:nth-of-type(19) {
  left: 92.5%;
  -webkit-animation-delay: 11s, .5s;
  animation-delay: 11s, .5s;
}

.mountains {
  height: 60%;
  display: block;
  position: relative;
  top: 34vh;
  left: 0;
  background-image: url(./mtns-bg1.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.bannerTitle {
  margin: 0 auto;
  position: relative;
  top: 20vh;
  text-align: center;
  color: #FFFFFF;
}

.bannerButton {
  margin: 0 auto;
  padding: 1rem;
  width: 10.5rem;
  position: relative;
  top: 30vh;
  display: block;
  font-size: 1.25rem;
  text-align: center;
  color: #FFFFFF;
  border:1px solid white;
}

.bannerButton i {
  padding-left: 1rem;
}

.bannerButton:hover {
  color: rgba(18, 17, 58, 1);
  background-color: rgba(250, 250, 250, .9);
}

@media only screen and (min-width: 1600px) {

  .mountains {
    height: 75%;
    transform: translateY(-5rem);
  }
}

@media only screen and (min-width: 1600px) {

  .mountains {
    height: 75%;
    transform: translateY(-5rem);
  }
}
