
.underlineBlack::after {
  content: "";
  display: block;
  border-bottom: .2rem solid black;
  width: 5rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: .5rem;
}

.underlineWhite::after {
  content: "";
  display: block;
  border-bottom: .2rem solid White;
  width: 5rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: .5rem;
}
