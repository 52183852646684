h1, h2, h3 {
  text-align: center;
}



div {
  margin: 0 auto;
  display: block;
}

.padTopLarge {
  padding-top: 7.5rem;
}

.padTopSmall {
  padding-top: 2.5rem;
}

.padTopSmallMarginBottom {
  margin-bottom: 7.5rem;
  padding-top: 2.5rem;
}



.padTopHeading {
  padding-top: 2.5rem;
}


ul {
  margin: 0 auto;
  padding: 0 .5rem;
  list-style: none;
  display: grid;
}

ul li {
  margin: 0 auto;
  padding: 1rem 0;
}
li:nth-of-type(4) {
  padding-bottom: 0;
}

.icon {
  margin: 0 auto;
  padding: 0;
  align-self: center;
  display: block;
}
.infoWrapper {
  align-self: center;
}

.infoTag {
  margin-top: .25rem;
  padding: .25rem;
  text-align: center;
}

.infoDescription {
  padding: 0 .5rem;
  font-size: 1.05rem;
  text-align: center;
}

li img {
  height: 60px;
}

.aboutBio {
  padding-bottom: 1rem;
}

.aboutBio:last-child {
  padding-bottom: 7.5rem;
}





@media only screen and (min-width: 600px) {

  .infoTag {
    padding: .25rem;
    font-size: 1.5rem;
  }

  .infoDescription {
    padding: 0 .5rem;
    font-size: 1.25rem;
  }

  .padTopSmallMarginBottom {
    max-width: 900px;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }

  li:nth-of-type(3) {
    padding-bottom: 0;
  }

  li:nth-of-type(4) {
    padding-bottom: 0;
  }

  li img {
    height: 85px;
  }
}


@media only screen and (min-width: 1200px) {

/* LOOKS BETTER AS COLUMN
  .aboutDesktopGrid {
    max-width: 90%;
    display: grid;
    grid-template-columns: 1fr 3fr;
  }

  .aboutBio {

    padding-top: 6rem;
    align-self: center;
  }
*/

}
