.projectWrapper {
  padding: 7.5rem 0 3.5rem 0;
  text-align: center;
  background-color: rgba(0, 128, 128, .9);
  color: white;
}

.projectWrapper h2 {
  padding-bottom: 2.5rem;
}
.projectWrapper h3 {
  padding-bottom: .5rem;
}

.gridWrapperProjects {
  margin: 1rem auto 2.5rem auto;
  display: grid;
  max-width: 75%;
}

.projectTile {
  padding: 1.5rem;
}

.projectTile:hover{
  background-color: rgba(0, 0, 0, .1);

}

.thumbnail {
  padding-bottom: .5rem;
  width: 200px;
  min-height: 110px;
  height: auto;
}

.subHeading {
  margin-bottom: 2.5rem;
  text-align: center;
}

@media only screen and (min-width: 600px) {

  .gridWrapperProjects {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }

  .thumbnail {
    width: 300px;
    min-height: 170px;
    height: auto;
  }
}
