/* REACH OUT SECTION SPECIFIC STYLES BELOW */
.reachOutWrapper {
    padding: 7.5rem 0 7.5rem 0;
    text-align: center;
    background-color: white;
}

.reachOutWrapper h4 {
  margin: 0;
  padding: .5rem;
  cursor: pointer;
  text-align: center;
}

.reachOut {
  padding: 1rem .5rem 0 .5rem;
  display: inline-block;
  font-size: 1.25rem;
  color: black;
  border-bottom: 1px solid black;
  transition: transform .5s;
}


/* CONTACT FORM SPECIFIC STYLES BELOW */

.formWrapper {
  margin: 0 auto;
  padding: 7.5rem 0 7.5rem 0;

  display: block;
  background-color: white;
  /* background-color: rgba(0, 128, 128, 1); */
  color: white;
}

.formWrapper h2 {
  padding-bottom: 1.75rem;
  color: black;
}

input {
  margin: 1rem auto 0 auto;
  padding: .75rem .5rem;
  max-width: 85%;
  display: block;
  font-size: 1.25rem;
  font-family: 'Lato', sans-serif;
  border-width: 0;
  border-style: none;
  outline: none;
  color: white;
  background-color: rgba(0, 128, 128, .8);
  border-radius: .25rem;
}

input:focus-visible {
  outline-offset: 0px;
  outline: -webkit-focus-ring-color auto 1px;
}

textarea {
  margin: 1rem auto 0 auto;
  padding: .75rem .5rem;
  min-width: 85%;
  display: block;
  font-size: 1.25rem;
  /* font-weight: 400; */
  font-family: 'Lato', sans-serif;
  border: none;
  color: white;
  background-color: rgba(0, 128, 128, .8);
  border-radius: .25rem;
}

input::placeholder, textarea::placeholder{
  color: rgba(255, 255, 255, .75);
}

.submitButton {
  margin: 1rem auto 0 auto;
  padding: .75rem;
  min-width: 87.5%;
  display: block;
  font-size: 1.25rem;
  outline: none;
  border-width: 0;
  border-style: none;
  color: white;
  background:  rgba(0, 128, 128, 1);
  border-radius: .25rem;
  /* border: 3px solid rgba(0, 128, 128, 1); */

}

.submitButton:hover {
  padding: .7rem;
  border: 1px solid black;
  /* color: teal; */
  background-color:  rgba(0, 128, 128, .8);
  cursor: pointer;
}

.subHeading {
  margin-bottom: 2.5rem;
  text-align: center;
  color: black;
}

@media only screen and (min-width: 350px) {


  input {
    min-width: 85%;
  }

  textarea {
    min-width: 85%;
  }

  .submitButton {
    min-width: 87%;
  }


}

@media only screen and (min-width: 499px) {


  form {
    margin: 0 auto;
    max-width: 500px;
  }

  input {
    min-width: 95%;
  }

  textarea {
    min-width: 95%;

  }

  .submitButton {
    cursor: pointer;
    min-width: 97%;
  }


}
